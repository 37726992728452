import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import shonLogo from "../../images/Shon_FH_CityCouncil_Sign.svg";



import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const ActionButons = tw.div`flex flex-row justify-center items-center space-x-4`

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const SecondaryLink = tw(PrimaryLinkBase)`rounded-full`

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover min-h-144`}`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-blue-300 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;


const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-secondary-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const SecondaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-secondary-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default (props) => {
  
  const { scrollRef } = props
  
  const executeScroll = () => scrollRef?.current?.scrollIntoView()

  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="#">
        Donate
      </NavLink> */}
      {/* <NavLink href="#">
        Blog
      </NavLink>
      <NavLink href="#">
        Locations
      </NavLink>
      <NavLink href="#">
        Pricing
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#">
        Hire Us
      </PrimaryLink>*/}
    </NavLinks> 
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          {/* <Heading> */}
              <img width={300} alt={'Shon Stevenson'} src={shonLogo} />
          {/* </Heading> */}
          <ActionButons>
            <PrimaryAction onClick={executeScroll}>Stay up-to-date</PrimaryAction>
            <a target="_blank" href="https://www.zeffy.com/en-US/donation-form/417d8fed-07d4-49e5-9d73-e352209f6050"><SecondaryAction>Donate</SecondaryAction></a>
          </ActionButons>
        </Content>
      </HeroContainer>
    </Container>
  );
};
