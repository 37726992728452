import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-blue-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-blue-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-blue-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-blue-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-blue-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "/photos/shon-military.jpg",
      subtitle: "Patriotism",
      title: "Military Service",
      description:
        "A veteran with a distinguished military career spanning 11 years, R. Shon Stevenson proudly served in the United States Army. During his time in the military, he was deployed on both humanitarian and combat missions. Through these experiences, Shon developed a deep sense of compassion, resilience, and dedication to service. His humanitarian deployments reinforced his commitment to improving the lives of others and addressing the needs within his own community.",
      url: ""
    },

    {
      imageSrc:
        "/photos/family.jpg",
      subtitle: "Family Values",
      title: "R. Shon's family",
      description: "Shon is a proud husband and father of three young children who understands the importance of a strong and vibrant community for families. His loving and supportive family serves as a constant source of inspiration and motivation in his pursuit of making a positive impact on the community he calls home. He is personally invested in creating a safe and prosperous environment that fosters the well-being of all residents.\n\n<br /><br />Having lived in Fruit Heights for the past 5 years and being the 5th generation of Stevenson in Davis County, Shon has developed a deep appreciation for the unique character and needs of our community. This generational knowledge of the area, coupled with his commitment to its growth and success, positions him as a candidate who can effectively address the specific challenges and opportunities we face.",
      url: ""
    },

    {
      imageSrc:
        "/photos/purpose.jpg",
      subtitle: "Purpose",
      title: "Staying true to our values",
      description:
        "As a candidate, Shon Stevenson is driven by a genuine desire to make a positive difference in the lives of our residents. His vision for the city is guided by his core values of integrity, transparency, and compassion. With his strong leadership skills, dedication to service, and deep understanding of the community, Shon is the ideal candidate to represent and advocate for the needs and aspirations of our city of good neighbors.",
      url: ""
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>R. Shon Stevenson for Fruit Heights City Council</HeadingTitle>
          <HeadingDescription>
            Shon is the right choice to represent the families and community members of Fruit Heights. 
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description dangerouslySetInnerHTML={{__html: card.description}}></Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
