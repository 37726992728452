import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //

import MailchimpSubscribe from "react-mailchimp-subscribe"

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-blue-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
// const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-blue-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-blue-700 hocus:-translate-y-px hocus:shadow-xl`;



const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  useEffect(() => {
    if(status === "success") clearFields()
  }, [status])

  const clearFields = () => {
    setName('');
    setEmail('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (name && email && email.includes("@") && onValidated({
      MERGE0: email,
      MERGE1: name
    })) {
      return true
    }
  }

  return(
    <FormContainer>

    <div className="mc__form-container" tw="mx-auto max-w-4xl">
      
    <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>

        <h3 className="mc__form-title mc__title">
          {status === "success" ? "Thank you for subscribing!" : "Join the Campaign Newsletter"}
        </h3>


        {status === "success" && (
          <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        )}

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}

        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        {status !== "success" ? (
          <div className="mc__field-container">
             
             <TwoColumn>
                <Column>
                  <InputContainer className="mc__field-container">
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input id="name-input" type="text" name="name" onChange={(e) => setName(e.target.value)} placeholder="E.g. John Doe" />
                  </InputContainer>
                  <InputContainer className="mc__field-container">
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="E.g. john@mail.com" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    {/* <Label htmlFor="name-input">Your Message</Label>
                    <TextArea id="message-input" name="message" placeholder="E.g. Details about your event"/> */}
                  </InputContainer>
                </Column>
              </TwoColumn>

              
          </div>
        ) : null}

        { status === "success" ? null : <SubmitButton type="submit" label="subscribe" value="Submit">Submit</SubmitButton> }

            </form>

      </div>
    </FormContainer>
  )
}

export default CustomForm