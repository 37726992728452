import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import CustomForm from "./CustomForm"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;


const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-blue-500 fill-current w-24`

const postUrl = `https://gmail.us12.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;


export default ( props ) => {

  const { scrollRef } = props

  return (
    <Container id="subscribeBox">
      <Content ref={scrollRef}>

            <MailchimpSubscribe url={postUrl} render={({subscribe, status, message}) => {
              return (
                <CustomForm status={status} message={message} onValidated={formData => subscribe(formData)} />
              )
            }} />
            

          <SvgDotPattern1 />

      </Content>
    </Container>
  );
};
